/* eslint-disable no-console */
import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { ILoaderResource } from '@phoenix7dev/shared-components/dist/loader/d';
import { IResource } from '@phoenix7dev/shared-components/dist/resources/d';
import { formatNumber as utilsFormatNumber } from '@phoenix7dev/utils-fe';

import variables from '../assets/styles/export.module.scss';
import { config } from '../config';
import { BonusStatus, EventTypes, UserBonus } from '../global.d';
import {
  client,
  getUserBonuses,
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setSlotConfig,
  setStressful,
} from '../gql';
import { MAXIMUM_FRACTION_DIGITS, MINIMUM_FRACTION_DIGITS, eventManager } from '../slotMachine/config';

import { IPixiAssets } from './d';

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, PIXI.LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    PIXI.Loader.shared.load((loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
    PIXI.Loader.shared.onError.once(() => {
      return reject();
    });
  });
};
export const loadPixiAssets = (assets: IPixiAssets[], baseUrl: string, isMobile: boolean): Promise<void> => {
  PIXI.Loader.shared.baseUrl = baseUrl;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    assets.forEach((asset) => {
      if (asset.isSpine) {
        const spriteImages = isMobile ? asset.spineImages?.mobile : asset.spineImages?.desktop;
        const images = spriteImages?.reduce((obj, imageSrc, index) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          obj[`${imageSrc}`] = PIXI.BaseTexture.from(
            `animations/${isMobile ? 'mobile' : 'desktop'}/${asset.name}/${imageSrc}`,
          );
          return obj;
        }, {});
        PIXI.Loader.shared.add(asset.name, asset.src, {
          metadata: {
            images,
          },
        });
      } else {
        PIXI.Loader.shared.add(asset.name, asset.src);
      }
    });
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (failed) {
        console.error(failed);
      }
    }

    return success ? resolve() : reject();
  });
};

export const loadImages = async (
  assets: IterableIterator<[string, IResource]>,
  cb?: CallableFunction,
): Promise<void> => {
  let promises: Promise<IResource>[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of assets) {
    promises.push(
      new Promise((resolve, reject) => {
        const asset: HTMLImageElement = new Image();
        asset.src = value.source;
        asset.onload = () => {
          if (cb) cb(value.key);
          resolve(value);
        };
        asset.onerror = () => reject(value);
      }),
    );
  }

  let tries = config.failureRetries;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      const result: Array<PromiseRejectedResult | PromiseFulfilledResult<IResource>> = await Promise.allSettled(
        promises,
      );
      const failed = _.filter(result, (asset) => asset.status === 'rejected') as Array<PromiseRejectedResult>;

      if (failed.length) {
        console.error(failed);
        promises = failed.map((rejected) => {
          return new Promise((resolve, reject) => {
            const asset: HTMLImageElement = new Image();
            asset.src = rejected.reason.source;
            asset.onload = () => {
              if (cb) cb(rejected.reason.key);
              resolve(rejected.reason);
            };
            asset.onerror = () => reject(rejected.reason);
          });
        });
        // eslint-disable-next-line no-continue
        continue;
      }
      success = true;
      break;
    } catch (err) {
      console.error(err);
    }
  }

  return success ? Promise.resolve() : Promise.reject();
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isPortrait = (width: number, height: number): boolean => {
  return width / height < 4 / 3;
};
export const isMobilePortrait = (width: number, height: number): boolean => {
  const isPortrait = height >= width;
  const maxWidth = parseInt(variables.breakpointMobilePortraitMax, 10);

  return isPortrait && width <= maxWidth;
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (isMobilePortrait(width, height)) {
    return height * (parseInt(variables.bottomHeightPercentMobilePortrait, 10) / 100);
  }
  return height * (parseInt(variables.bottomHeightPercent, 10) / 100);
};

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0, coinValue = setCoinValue()): number => {
  return (coins * coinValue) / 100;
};
export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};
export const formatNumber = (currency = 'FUN', value = 0, showCurrency = false): string => {
  return utilsFormatNumber({
    currency,
    value,
    showCurrency,
    minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
    maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
  });
};

export const nextTick = (callback: () => void): NodeJS.Immediate => setImmediate(callback);

export const updateTextScale = (text: PIXI.Text, maxWidth: number, maxHeight: number): void => {
  text.scale.set(1, 1);
  text.updateText(true);
  const ratio = Math.min(1, Math.min(maxWidth / text.width, maxHeight / text.height));
  text.scale.set(ratio, ratio);
};

export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = (stage?.error as unknown) as string;
  setStressful({
    show: true,
    type: 'network',
    message:
      (i18n.t([errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN']) as string) ||
      ((error as unknown) as string),
  });
};

export const queryParams = new URLSearchParams(window.location.search);

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i];

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0;
};

export const updateCoinValueAfterBonuses = (): void => {
  // updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
  const coinValue = setSlotConfig().clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
  const coinAmount = findSubstituteCoinAmount(setCoinAmount(), setSlotConfig().clientSettings.coinAmounts.default);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};

export const getUserActiveBonuses = () => {
  return client.query<{ userBonuses: UserBonus[] }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });
};
